import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportsService from "./reports-service";
import commonService from "../../common/js/common-api-service";
import utilityService from "../../utility/js/utility-api-bin-management";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectData: [],
      selectedBusiness: "",
      businessData: [],
      selectedPartType: "",
      partTypeData: [],
      selectedParentClass: "",
      parentClassData: [],
      selectedPartClass: "",
      partClassData: [],
      selectedWarehouse: "",
      warehouseData: [],
      selectedInventoryLoc: "",
      inventoryLocData: [],
      inventoryLocChanged: true,
      totalRecords: 0,
      searchStocks: "",
      stockItems: [],
      stockStatusDeleteDialog: false,
      deleteStockDet: [],
      specificDialog: false,
      partClassSearchValid: false,
      firstSearch: "",
      firstSearchItems: [],
      secondSearch: "",
      secondSearchItems: [],
      txtPartNumber: "",
      partDetailsShow: false,
      totalPartRecords: 0,
      searchPartSearch: "",
      partDetailsSearch: [],
      searchPartNo: "",
      showPartNum: false,
      showBusiness: false,
      showPartType: false,
      showParentClass: false,
      showPartClass: false,
      showWarehouse: false,
      showInventoryLoc: false,
      disableRun: false,
      hideSpecific: false,
      hideAll: false,
      partSearchKey: "",
      showPartNumDetails: false,
      partResultList: [],
      showPartDetails: false,
      dropdownData: [],
      debouncedGetPartList: [],
      allRecord: {
        bu_key: 0,
        business: "ALL",
        sbu_key: 0,
        sub_business: "ALL",
        ptype_key: 0,
        part_type: "ALL",
        parent_class_key: 0,
        parent_class: "ALL",
        class_key: 0,
        class: "ALL",
        ware_key: 0,
        ware: "ALL",
        loc: "ALL",
      },
      disableParentClass: false,
      disablePartClass: false,
      headersStocks: [
        { text: "Project", align: "start", value: "Project", class: "primary customwhite--text", width: "24%" },
        { text: "Business", value: "Business", class: "primary customwhite--text", width: "20%" },
        { text: "Part Type", value: "Part Type", class: "primary customwhite--text", width: "8%" },
        { text: "Parent Class", value: "Parent Class", class: "primary customwhite--text", width: "8%" },
        { text: "Part Class", value: "Part Class", class: "primary customwhite--text", width: "6%" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text", width: "8%" },
        { text: "Location", value: "Location", class: "primary customwhite--text", width: "6%" },
        { text: "Part Number", value: "Part Number", class: "primary customwhite--text", width: "6%" },
        { text: "Last Run", value: "Last Run", class: "primary customwhite--text", width: "10%" },
        { text: "Actions", value: "Actions", class: "primary customwhite--text", width: "2%" },
        { text: "Delete", value: "Delete", class: "primary customwhite--text", width: "2%" },
      ],
      headersPartSearchTbl: [
        { text: "Part No", align: "start", value: "PartNo", class: "primary customwhite--text" },
        { text: "Project", value: "Project", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Model", value: "Model", class: "primary customwhite--text" },
        { text: "Part Type", value: "Part Type", class: "primary customwhite--text" },
        { text: "OEM Model", value: "OEM Model", class: "primary customwhite--text" },
        { text: "OEM Part", value: "OEM Part", class: "primary customwhite--text" },
      ],
      max10Rule: [(v) => (v || "").length <= 10 || "Max Length of 10 character", (v) => !!v || "Field is required"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
    this.stockSavedStatus();
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectData = [];
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedPartType = "";
      this.partTypeData = [];
      this.selectedParentClass = "";
      this.parentClassData = [];
      this.selectedPartClass = "";
      this.partClassData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.selectedInventoryLoc = "";
      this.inventoryLocData = [];
      this.inventoryLocChanged = true;
      this.totalRecords = 0;
      this.searchStocks = "";
      this.stockItems = [];
      this.deleteStockDet = [];
      this.showPartNum = false;
      this.showBusiness = false;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.disableRun = false;
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.partResultList = [];
      this.showPartDetails = false;
      this.dropdownData = [];
      this.disablePartParent = false;
      this.disableParentClass = false;
      this.disablePartClass = false;
      this.getProjectData();
      this.stockSavedStatus();
    },
    //Get Project data on page load
    async getProjectData() {
      let projectData = await commonService.getProject("get", this.userId);
      if (projectData?.ProjectInfo) {
        this.projectData = projectData.ProjectInfo;
      }
    },
    //Get table data on page load
    async stockSavedStatus() {
      let obj = {
        user_id: parseInt(this.userId),
        pass: 1,
      };
      let showMessage = false;
      let stockDetails = await reportsService.stockSavedStatus("post", obj, showMessage);
      if (stockDetails.message !== "NA") {
        const newArr = stockDetails.map((obj) => {
          return {
            ...obj,
            "Last Run": Utility.convertESTToLocal(obj["Last Run"]),
          };
        });
        this.stockItems = newArr;
        this.totalRecords = newArr.length;
      } else {
        this.totalRecords = 0;
      }
    },
    //Delete button click event
    stockDelete(item) {
      this.deleteStockDet = [];
      this.deleteStockDet = item;
      this.stockStatusDeleteDialog = true;
    },
    //Delete Stock Status Record
    async deleteStock() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        user_id: parseInt(this.userId),
        pass: 2,
        ssrun_id: parseInt(this.deleteStockDet.ssrun_id),
      };
      this.axios
        .post("/rt/stock_status_saved_run", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.stockSavedStatus();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.stockStatusDeleteDialog = false;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Run Report
    runReport() {
      let sqlData = {
        proj_id: parseInt(this.selectedProject),
        bu_id: parseInt(this.selectedBusiness == "" ? 0 : this.selectedBusiness),
        sbu_id: 0,
        ptype_id: parseInt(this.selectedPartType == "" ? 0 : this.selectedPartType),
        parent_class_id: parseInt(this.selectedParentClass == "" ? 0 : this.selectedParentClass),
        class_id: parseInt(this.selectedPartClass == "" ? 0 : this.selectedPartClass),
        ware_id: parseInt(this.selectedWarehouse == "" ? 0 : this.selectedWarehouse),
        loc: this.selectedInventoryLoc,
        part_num: "",
        user_id: parseInt(this.userId),
      };
      this.$router.push({
        name: "stockStatusReportDetails",
        params: {
          apiData: btoa(JSON.stringify(sqlData)),
        },
      });
    },
    //Run button click event in the data table
    async runStocks(item) {
      if (item.json !== "") {
        this.$router.push({
          name: "stockStatusReportDetails",
          params: {
            apiData: btoa(item.json),
          },
        });
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "This doesn't have a valid JSON",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //On Project selection
    async projectSelected() {
      let dropdownData = await reportsService.getStockSavedPrompts("get", parseInt(this.userId), parseInt(this.selectedProject));
      if (dropdownData.message !== "NA") {
        this.dropdownData = [];
        this.dropdownData = dropdownData;
        this.businessData = this.dropdownData;
        this.selectedBusiness = "";
        this.selectedPartType = "";
        this.selectedParentClass = "";
        this.selectedPartClass = "";
        this.selectedWarehouse = "";
        this.selectedInventoryLoc = "";
        this.showPartNum = true;
        this.showBusiness = false;
        this.showPartType = false;
        this.showParentClass = false;
        this.showPartClass = false;
        this.showWarehouse = false;
        this.showInventoryLoc = false;
        this.disableRun = false;
        this.hideSpecific = false;
        this.hideAll = false;
        this.clearPartShip();
      }
    },
    //Business Selected
    businessSelected() {
      this.selectedPartType = "";
      this.selectedParentClass = "";
      this.selectedPartClass = "";
      this.selectedWarehouse = "";
      this.selectedInventoryLoc = "";
      this.showPartType = true;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.disableRun = false;
      this.hideSpecific = true;
      if (this.selectedBusiness !== 0) {
        this.partTypeData = this.businessData.filter((obj) => obj.bu_key == this.selectedBusiness);
        this.partTypeData.unshift(this.allRecord);
      } else {
        this.partTypeData = this.businessData;
      }
    },
    //Part Type Selected
    partTypeSelected() {
      this.selectedParentClass = "";
      this.selectedPartClass = "";
      this.selectedWarehouse = "";
      this.selectedInventoryLoc = "";
      this.showParentClass = true;
      this.showPartClass = true;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.disableRun = false;
      this.disablePartParent = true;
      if (this.selectedPartType !== 0) {
        this.parentClassData = this.partTypeData.filter((obj) => obj.ptype_key == this.selectedPartType);
        this.parentClassData.unshift(this.allRecord);
        this.partClassData = this.partTypeData.filter((obj) => obj.ptype_key == this.selectedPartType);
        this.partClassData.unshift(this.allRecord);
      } else {
        this.parentClassData = this.partTypeData;
        this.partClassData = this.partTypeData;
      }
    },
    //Parent Class Selected
    parentClassSelected() {
      this.selectedWarehouse = "";
      this.selectedInventoryLoc = "";
      this.showWarehouse = true;
      this.showInventoryLoc = false;
      this.disableRun = false;
      this.disableParentClass = false;
      this.disablePartClass = true;
      if (this.selectedParentClass !== 0) {
        this.warehouseData = this.parentClassData.filter((obj) => obj.parent_class_key == this.selectedParentClass);
        this.warehouseData.unshift(this.allRecord);
      } else {
        this.warehouseData = this.parentClassData;
      }
    },
    //Part Class Selected
    partClassSelected() {
      this.selectedWarehouse = "";
      this.selectedInventoryLoc = "";
      this.showWarehouse = true;
      this.showInventoryLoc = false;
      this.disableRun = false;
      this.disableParentClass = true;
      this.disablePartClass = false;
      if (this.selectedPartClass !== 0) {
        this.warehouseData = this.partClassData.filter((obj) => obj.class_key == this.selectedPartClass);
        this.warehouseData.unshift(this.allRecord);
      } else {
        this.warehouseData = this.partClassData;
      }
    },
    //Warehouse Selected
    warehouseSelected() {
      this.selectedInventoryLoc = "";
      this.showInventoryLoc = true;
      this.disableRun = false;
      if (this.selectedWarehouse !== 0) {
        this.inventoryLocData = this.warehouseData.filter((obj) => obj.ware_key == this.selectedWarehouse);
        this.inventoryLocData.unshift(this.allRecord);
      } else {
        this.inventoryLocData = this.warehouseData;
      }
    },
    //Specific Part Number click event
    async specificPartNumber() {
      let response = await utilityService.partSearchDetails();
      if (response !== null && response !== undefined) {
        this.firstSearchItems = response.Dropdown1;
        this.secondSearchItems = response.Dropdown2;
        this.firstSearch = "part_num";
        this.secondSearch = "Equals";
        this.specificDialog = true;
      }
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportsService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },
    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
    showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.partDetailsShow = false;
      this.specificDialog = false;
      this.showBusiness = false;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.disableRun = true;
      this.hideAll = true;
    },
    //All Part Clicked
    allPartNumber() {
      this.showBusiness = true;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.disableRun = false;
      this.hideSpecific = true;
    },
    //Part Search click
    async partSearchClick() {
      if (this.secondSearch == "BeginsWith") {
        this.searchPartNo = this.txtPartNumber + "%";
      } else if (this.secondSearch == "Contains") {
        this.searchPartNo = "%" + this.txtPartNumber + "%";
      } else if (this.secondSearch == "Equals") {
        this.searchPartNo = this.txtPartNumber;
      }
      let obj = {
        user_id: parseInt(this.userId),
        proj_id: parseInt(this.selectedProject),
        part_num: this.firstSearch == "part_num" ? this.searchPartNo : "",
        model: this.firstSearch == "model" ? this.searchPartNo : "",
        oem_model: this.firstSearch == "oem_model" ? this.searchPartNo : "",
        part_desc: this.firstSearch == "part_desc" ? this.searchPartNo : "",
        oem_part: this.firstSearch == "oem_part" ? this.searchPartNo : "",
      };
      let showMessage = false;
      let response = await reportsService.stockStatusPartSearch("post", obj, showMessage);
      if (response.message !== "NA") {
        this.totalPartRecords = response.length;
        this.partDetailsSearch = response;
        this.partDetailsShow = true;
      } else {
        this.totalPartRecords = 0;
        this.partDetailsSearch = [];
        this.partDetailsShow = false;
      }
    },
    //Clear button click event
    clearSearch() {
      this.inventoryLocChanged = true;
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.selectedPartType = "";
      this.selectedParentClass = "";
      this.selectedPartClass = "";
      this.selectedWarehouse = "";
      this.selectedInventoryLoc = "";
    },
    //Cancel in Part Search
    cancelPartSearch() {
      this.partClassSearchValid = false;
      this.firstSearch = "";
      this.secondSearch = "";
      this.txtPartNumber = "";
      this.partDetailsShow = false;
      this.totalPartRecords = 0;
      this.searchPartSearch = "";
      this.searchPartNo = "";
      this.$refs.partClassSearchForm.resetValidation();
    },
    //Close button in Part Search popup
    closeDialog() {
      this.partClassSearchValid = false;
      this.firstSearch = "";
      this.firstSearchItems = [];
      this.secondSearch = "";
      this.secondSearchItems = [];
      this.txtPartNumber = "";
      this.partDetailsShow = false;
      this.totalPartRecords = 0;
      this.searchPartSearch = "";
      this.partDetailsSearch = [];
      this.searchPartNo = "";
      this.$refs.partClassSearchForm.resetValidation();
      this.specificDialog = false;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
  },
};
